<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-8">
        <v-card class="onbox_page_styling rounded-0">
          <div class="col-12 p-0">
            <div class="py-4 px-4 border_b_silver">
              <span class="card-label font-weight-bolder Main_Blue"
                >Inbox
              </span>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            item-key="name"
            class="p-4 text-dark-75 table-borderless"
          >
            <template v-slot:item="props">
              <tr
                class="border__ch"
                :style="
                  props.item.seen_at !== null
                    ? 'background: rgb(185 229 251 / 17%)'
                    : 'background: white'
                "
              >
                <td
                  class="font-weight-bolder Main_Blue"
                  style="font-size: 14px; width: 30%"
                >
                  {{ props.item.sender.name }}
                </td>
                <td
                  class="font-weight-bolder Main_Blue"
                  style="
                    font-size: 13px;
                    font-weight: 500 !important;
                    width: 30%;
                  "
                >
                  {{ props.item.message_title }}
                </td>

                <!-- <v-expansion-panels>
                  <v-expansion-panel
                    :style="
                      props.item.seen_at !== null
                        ? 'background: #eeeeee'
                        : 'background: white'
                    "
                    class="font-weight-bolder Main_Blue"
                  >
                    <v-expansion-panel-header
                      class="font-weight-bolder Main_Blue"
                      style="font-weight: 500 !important"
                    >
                      view
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div style="display: flex">
                        <div>
                          <img width="23" />
                        </div>

                        <div v-html="props.item.message"></div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels> -->
                <!-- <td>
                  <a
                    v-if="props.item.file !== null"
                    :href="mediaUrl + props.item.file"
                  >
                    <button
                      type="button"
                      class="
                        custom-add-new-record-button
                        btn_dash_ch
                        v-btn v-btn--is-elevated v-btn--has-bg
                        theme--light
                        v-size--default
                      "
                    >
                      <span class="v-btn__content">Download</span>
                    </button></a
                  >
                  <p v-if="props.item.file == null">-</p>
                </td> -->
                <td class="pr-0">
                  <button
                    type="button"
                    class="custom-add-new-record-button btn_dash_ch mr-2 mt-3"
                    fab
                    small
                    @click="seenButtonClick(props.item)"
                  >
                    <span class="v-btn__content pt-1">Mark as read</span>
                  </button>
                  <button
                    type="button"
                    class="custom-add-new-record-button btn_red__ch"
                    fab
                    small
                    @click="seenButtonClick(props.item)"
                  >
                    <span class="v-btn__content">Delete</span>
                  </button>
                  <button
                    v-on:click="singleDetail(props.item)"
                    type="button"
                    class="btn_dash_ch__ btn_span_ch float-right"
                  >
                    <img
                      src="\images/job_application/Conversations_icon_normal.svg"
                      alt=""
                      style="width: 30px !important; height: 30px !important"
                      width="30"
                      height="30"
                    />
                  </button>
                </td>
                <!-- <td>
                </td> -->
              </tr>
              <tr v-bind:key="i">
                <td style="height: 12px" colspan="12"></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div class="col-lg-4 p-0 sidbar_ch_new1" v-if="detail">
        <div class="col-lg-12 pl-0 pr-0">
          <div class="card sidebar">
            <div class="card-body widget border-bottom cardbodych" style="">
              <h5
                class="
                  card-label
                  font-weight-bolder
                  Main_Blue
                  position_style_ch
                  border-0
                "
              >
                Conversation
              </h5>
              <button
                class="badge badge-danger badge-pill"
                @click="detail = false"
              >
                X
              </button>
            </div>
            <div class="col-12 bg-white">
              <div class="msg_ch" v-html="detail.message"></div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      mediaUrl: MEDIA_URL,
      search: "",
      loading: true,
      success: false,
      detail: null,
      token: `Bearer ${localStorage.getItem("token")}`,
      headers: [
        { text: "Sender", value: "sender" },
        { text: "Title", value: "message_title" },
        // { text: "Message", value: "message" },
        // { text: "File", value: "file" },
        { text: "Actions", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/get-inbox`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    singleDetail(item) {
      this.detail = item;
    },
    seenButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/seen-single-msg/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/delete-single-msg/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>
